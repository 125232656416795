import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBolt,
    faTools,
    faUnlockAlt,
    faCheck,
    faHome,
    faBuilding,
    faIndustry,
} from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageSection from '../components/page-section';
import SocialIcons from '../components/social-icons';
import Carousel from '../components/carousel';

import servicesData from '../data/services.json';
import carouselData from '../data/services-carousel.json';

import FaBolt from '../images/svgs/bolt.svg';
import FaUnlock from '../images/svgs/unlock-alt.svg';
import FaTools from '../images/svgs/tools.svg';

import './services.scss';

const seo = {
    title: 'Services',
    description: `Services offered by JPF Solutions Ltd. We work on small to large-scale projects offering electrical, security and renovation services. All services are available on residential, commercial and industrial properties.`,
};

const tabContentsArray = [
    {
        title: `Electrical`,
        tabId: 'electrical',
        typeIcon: FaBolt,
        typeIconSmall: faBolt,
        servicesList: servicesData.electrical,
    },
    {
        title: `Security`,
        tabId: 'security',
        typeIcon: FaUnlock,
        typeIconSmall: faUnlockAlt,
        servicesList: servicesData.security,
    },
    {
        title: `Renovations`,
        tabId: 'renovations',
        typeIcon: FaTools,
        typeIconSmall: faTools,
        servicesList: servicesData.renovations,
    },
].map(serviceType => (
    <div key={serviceType.tabId}>
        <div className="services__title">{serviceType.title} Services</div>
        <div className="services__list">
            <serviceType.typeIcon className="services__type-icon" />
            {serviceType.servicesList.map(service => (
                <div key={service} className="services__service">
                    <div className="services__icon">
                        <FontAwesomeIcon icon={serviceType.typeIconSmall} />
                    </div>
                    <p className="services__text">{service}</p>
                </div>
            ))}
            <div className="services__service services__service--last">
                <div className="services__icon">
                    <FontAwesomeIcon icon={serviceType.typeIconSmall} />
                </div>
                <p className="services__text services__more">
                    and many more&hellip;
                </p>
            </div>
        </div>
    </div>
));

const getPageData = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(
                filter: { relativeDirectory: { eq: "carousel/services" } }
            ) {
                edges {
                    node {
                        childImageSharp {
                            fluid(maxWidth: 400, quality: 70) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        relativePath
                    }
                }
            }
            site {
                siteMetadata {
                    contactInfo {
                        name
                        telNumber
                        email
                    }
                }
            }
        }
    `);

    const carouselImagesBySrc = data.allFile.edges.reduce((acc, edge) => {
        acc[edge.node.relativePath] = edge.node;

        return acc;
    }, {});

    return {
        carouselImagesBySrc,
        siteMetadata: data.site.siteMetadata,
    };
};

const ServicesPage = () => {
    const { carouselImagesBySrc, siteMetadata } = getPageData();

    const [selectedTab, toggleSelectedTab] = useState('electrical');

    return (
        <Layout
            pageId="services"
            layoutClassName="services-page"
            title="Services"
        >
            <SEO
                title={seo.title}
                description={seo.description}
                relativeCanonicalSlug="services"
            />

            <div className="content">
                <PageSection className="intro">
                    <div className="page-section__image intro__image-container">
                        <div className="intro__carousel">
                            <Carousel uniqueCarouselName="services-carousel-1">
                                {carouselData
                                    .map((imageData, ix) => ({
                                        ...imageData,
                                        key: ix + 1,
                                    }))
                                    .map(carouselItem => (
                                        <div
                                            className="glide__image-container"
                                            key={carouselItem.key}
                                        >
                                            <Img
                                                fluid={
                                                    carouselImagesBySrc[
                                                        carouselItem.src
                                                    ].childImageSharp.fluid
                                                }
                                                loading="eager"
                                                className="glide__image"
                                                alt={carouselItem.alt}
                                            />
                                        </div>
                                    ))}
                            </Carousel>
                        </div>
                    </div>
                    <div className="page-section__text">
                        <p className="page-section__tagline">
                            We work on small to large-scale projects offering
                            electrical, security and renovation services
                        </p>
                        <p className="page-section__sub-tagline">
                            No job is too small or too big for us
                        </p>
                        <p className="page-section__sub-tagline">
                            Check out some examples of the services we offer
                            below
                        </p>
                    </div>
                </PageSection>
                <div className="properties-banner">
                    <p className="properties-banner__message">
                        All services listed are available on residential,
                        commercial, industrial properties.
                    </p>
                    <div className="properties-banner__properties">
                        <div className="properties-banner__property">
                            <FontAwesomeIcon icon={faHome} /> Residential{' '}
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="properties-banner__property-icon"
                            />
                        </div>
                        <div className="properties-banner__property">
                            <FontAwesomeIcon icon={faBuilding} /> Commercial{' '}
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="properties-banner__property-icon"
                            />
                        </div>
                        <div className="properties-banner__property">
                            <FontAwesomeIcon icon={faIndustry} /> Industrial{' '}
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="properties-banner__property-icon"
                            />
                        </div>
                    </div>
                </div>
                <div className="services">
                    <div className="services__tabs">
                        {[
                            {
                                tabName: `Electrical`,
                                tabId: `electrical`,
                                tabIcon: faBolt,
                            },
                            {
                                tabName: `Security`,
                                tabId: `security`,
                                tabIcon: faUnlockAlt,
                            },
                            {
                                tabName: `Renovations`,
                                tabId: `renovations`,
                                tabIcon: faTools,
                            },
                        ].map(tab => (
                            <button
                                key={tab.tabId}
                                type="button"
                                className={`services__tab ${
                                    selectedTab === tab.tabId
                                        ? 'services__tab--active'
                                        : 'services__tab--inactive'
                                }`}
                                onClick={() => toggleSelectedTab(tab.tabId)}
                                aria-label={tab.tabName}
                            >
                                <FontAwesomeIcon icon={tab.tabIcon} />{' '}
                                <span className="services__tab-name">
                                    {tab.tabName}
                                </span>
                            </button>
                        ))}
                    </div>
                    <div className="services__tabs-content">
                        {selectedTab === 'electrical' && tabContentsArray[0]}
                        {selectedTab === 'security' && tabContentsArray[1]}
                        {selectedTab === 'renovations' && tabContentsArray[2]}
                    </div>
                </div>
                <div className="contact">
                    <div className="contact__text">
                        <p>
                            To enquire about any of our services, please
                            don&apos;t hesitate to get in touch!
                        </p>
                    </div>
                    <div className="contact__info">
                        <p>{siteMetadata.contactInfo.telNumber}</p>
                        <p>
                            <a
                                href={`mailto:${siteMetadata.contactInfo.email}`}
                            >
                                {siteMetadata.contactInfo.email}
                            </a>
                        </p>
                        <SocialIcons />
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default ServicesPage;
